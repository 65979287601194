import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Overview`}</h2>
    <p>{`Character illustration is an important aspect of digital product design. It involves creating a virtual character or avatar that represents a brand, organization, or user within a digital interface. This character can enhance user engagement, retention, and emotional connection to the product. The character’s appearance, behavior, and dialogue should be consistent with the product’s brand identity and tone of voice. Overall, effective character design in digital products can help establish a strong brand identity and improve the user experience.`}</p>
    <h2>{`Body Type`}</h2>
    <p>{`Every product has a different character with its own uniqueness. Therefore, we need to adjust the scale of each character both in terms of size and shape. Shape can convey a story, show personality, and evoke emotional responses from the audience without requiring any words.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/illus-char-guidance-body-type.svg",
      "alt": "body type illustration"
    }}></img>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/illus-char-guidance-body-type-female.svg",
      "alt": "body type illustration female"
    }}></img>
    <h3><strong parentName="h3">{`Squares`}</strong></h3>
    <p>{`Squares a reliable shape that symbolizes stability, honesty, and strength.`}</p>
    <p><strong parentName="p">{`Tip:`}</strong>{` The compartmentalization of body parts such as the jaw, shoulders, and hands should not only be firm and strong, but also feel difficult to move.`}</p>
    <h3><strong parentName="h3">{`Triangles`}</strong></h3>
    <p>{`Triangle is closely related to energy and strength, indicating direction. Triangles show movement, direction, and continuous processes depending on the direction of their points (up or down). Triangles can be stable or unstable.`}</p>
    <p><strong parentName="p">{`Tip:`}</strong>{` The triangle is a sharp shape. Enlarging or exaggerating the size and length of this shape can indicate how a character is very frightening and menacing.`}</p>
    <h3><strong parentName="h3">{`Circles`}</strong></h3>
    <p>{`Circle has freedom of movement. It can also represent strength and energy in that movement. The circle represents community, integrity, perfection, and a sense of wholeness and completeness.`}</p>
    <p><strong parentName="p">{`Tip:`}</strong>{` The circle is an organic and natural shape. Rounding the edges and adding curves can evoke a warm and friendly feeling.`}</p>
    <div className="divi" />
    <h2>{`Body Ratio`}</h2>
    <p>{`When it comes to character illustration, body ratio can be an important factor in creating a visually appealing and believable character design. However, there are no hard and fast rules when it comes to body ratio in character design, as it can vary widely depending on the style and intended audience of the illustration.`}</p>
    <p>{`That being said, there are some general guidelines that can be helpful in creating a well-proportioned character.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Head-to-body ratio:`}</strong>{` The size of the character’s head relative to their body can have a big impact on their overall appearance. In general, a head-to-body ratio of 1:6 to 1:8 can create a balanced and proportionate look.`}</li>
    </ul>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`Shoulder-to-hip ratio:`}</strong>{` A wider shoulder-to-hip ratio can create a more muscular and athletic appearance, while a narrower ratio can create a more slender and graceful look.`}</li>
    </ul>
    <br />
    <ul>
      <li parentName="ul"><strong parentName="li">{`Limb-to-torso ratio:`}</strong>{` The length of the character’s limbs relative to their torso can affect their overall posture and body language. Longer limbs can create a more lanky and awkward appearance, while shorter limbs can create a more compact and powerful look.`}</li>
    </ul>
    <div className="divi" />
    <h2>{`Female vs Male Faces`}</h2>
    <p>{`It is hard to compare male to female faces simply because each face is unique,and we tent do simplify our illustration but there are some features in the face that can make a male portrait look more masculine or a femaleortrait look more feminine.`}</p>
    <p><strong parentName="p">{`1. Differences in the Face Shape`}</strong></p>
    <p>{`Generally, men have longer and larger faces than women, What I find really helps where it concerns the face shape, is by drawing a male portrait with a square aspects to it. They usually has sharp corners as do the jawline and the neck line`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/male-female-face-shapes.svg",
      "alt": "male versus female illustration"
    }}></img>
    <p><strong parentName="p">{`2. Differences in the Eyebrows`}</strong></p>
    <p>{`The male portrait far thicker eyebrows than the female portrait. You can accentuate male features further by drawing the eyebrows without curves, opting for sharp corners instead and to soften the face of the female more, you would give the eyebrows soft curves rather than sharp corners.`}</p>
    <p>{`Keeping in mind that more women than men pluck their eyebrows, if for some reason you wished to draw a male model/actor, you’d draw the eyebrows very neat as they are likely to pluck them due to their job.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/male-female-eyebrows.svg",
      "alt": "male versus female illustration"
    }}></img>
    <p><strong parentName="p">{`3. Differences in the Eyes`}</strong></p>
    <p>{`For the eyes, the main thing that can make a female portrait more feminine, is by giving her long lashes that curl outwards, helping to give the impression of the eyes being bigger. You can make them more compact and darker to have it appear as though the woman is wearing mascara. For a male portrait on the other hand, keeping the lashes very short will help draw the eyes appear more masculine, sometimes I don’t draw any lashes at all.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/male-female-eyes.svg",
      "alt": "male versus female illustration"
    }}></img>
    <p><strong parentName="p">{`4. Differences in the Mouth and Lips`}</strong></p>
    <p>{`With men, you can sometimes leave out the upper lip entirely. What is great with drawing women is that, the moment the definition made on the upper lip is present, the mouth will have a more feminine air to it. Doesn’t matter if the lips are full, wide, small, thin, etc, giving the upper lip as much detail as the bottom and defining the indent between upper lip and nose, will instantly make a mouth appear more feminine in a portrait.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/male-female-mouth-lips.svg",
      "alt": "male versus female illustration"
    }}></img>
    <p><strong parentName="p">{`5. Differences in the Hair`}</strong></p>
    <p>{`The most obvious way to make a portrait look more masculine, would be to add facial hair of course. The hairline for a man is further back than a woman, giving the appearance of a larger forehead. The hairline is also more rounded for a female which further reduces the appearance of the forehead.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/male-female-hair.svg",
      "alt": "male versus female illustration"
    }}></img>
    <div className="divi" />
    <h2>{`Expression`}</h2>
    <p>{`Expression is the visual and unspoken language of the character’s mood and personality. Regardless of the illustration style being created, when we can understand how each emotion affects specific facial areas, it will be easier to express them.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/illustrations/illus-char-guidance-expression.svg",
      "alt": "expression illustration"
    }}></img>
    <p><strong parentName="p">{`1. Happy Face`}</strong></p>
    <p>{`When we smile, the corners of the mouth and the line between the lips point upwards. We can also create a closed mouth for a gentle and peaceful smile, or for a more expressive smile, draw the mouth slightly open. When we smile, the cheekbones rise up, causing the eyes to squint (“smize”) by making the lower eyelids slightly curved. Then add eyebrows and other details`}</p>
    <p><strong parentName="p">{`2. Sad Face`}</strong></p>
    <p>{`In a sad face, the corners of the mouth are downturned. We can intensify the “despair” by opening the mouth; if not, close the lips and draw a line outside the corners of the mouth to create a tense, disappointed, or frustrated expression. The eyes and eyebrows are the main features of a sad face. The eyebrows are furrowed and the inner corners of the eyebrows are drawn down and up, causing wrinkles on the forehead. The outer corners of the upper eyelids are slightly downward sloping (“Puppy Eyes”), and the gaze can be teary, watery, or tilted to one side.`}</p>
    <p><strong parentName="p">{`3. Angry Face`}</strong></p>
    <p>{`We can start by drawing a straight-ish line for the mouth, slightly curving downwards towards the outer corner, and leaving a little gap between the line and the end of the mouth to add tension. We can also draw a curve under the mouth for a clenched jaw. The shape of the eyes is narrow and covers the upper and lower parts of the iris. The eyebrows are an important feature of an angry face. They are pulled down and together, like squinting. Adding vertical wrinkles between the eyebrows will immediately give the desired effect.`}</p>
    <p><strong parentName="p">{`4. Scared Face: Surprise, worry, shock, and horror`}</strong></p>
    <p>{`Add more white color around the dilated pupils and adjust how much of the iris is covered by the upper and lower eyelids. The eyebrows are lifted and furrowed, causing wrinkles in the middle of the eyebrows. In a state of fear, the mouth usually opens wide. However, changing it from an “o” shape to a more slanted shape will completely change the type of expression that is trying to be conveyed.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      